(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/widget/assets/javascripts/track-click.js') >= 0) return;  svs.modules.push('/components/content/widget/assets/javascripts/track-click.js');

'use strict';

(() => {
  const trackClick = event => {
    const element = event.currentTarget;
    const clickTrackingString = element.dataset.clickTracking;

    const getWidgetPosition = () => {
      const widgetStdClass = 'widget';
      const allWidgetsInDom = document.getElementsByClassName(widgetStdClass);
      const thisWIdgetElement = element.closest('div.widget');
      return Array.prototype.indexOf.call(allWidgetsInDom, thisWIdgetElement) + 1;
    };
    if (clickTrackingString) {
      const evt = {
        name: clickTrackingString,
        position: getWidgetPosition()
      };
      if (element.getAttribute('target') && element.getAttribute('target') !== '_self') {
        svs.components.analytics.trackPromoClick(evt);
        return true;
      }
      event.preventDefault();
      svs.components.analytics.trackPromoClick(evt, () => {
        document.location = element.getAttribute('href');
      });
      return false;
    }
  };
  $('body').on('click', '*[data-click-tracking]', trackClick);
})();

 })(window);